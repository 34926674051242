<template>
  <div
    class="row compare-row striped"
    v-if="feature.show !== false"
    :class="{ 'compare-summary': feature.name === 'CompareSummary' }"
  >
    <div class="col-12 px-lg-5">
      <div class="row" v-show="feature.name !== 'CompareSummary'">
        <div class="col-12">
          <h4>
            {{ feature.label }}
            <i
              v-show="feature.tooltip"
              class="fas fa-info-circle"
              @click="showModal()"
            >
            </i>
          </h4>
        </div>
      </div>

      <div class="row">
        <div
          class="col text-left d-none d-lg-block desktop-compare-row"
          :class="getRankClass(vehicle)"
          v-for="(vehicle, index) in vehicles"
          :key="index"
        >
          <div class="row mb-4" v-show="feature.name !== 'CompareSummary'">
            <div class="col">
              <feature-score :vehicle="vehicle" :feature-name="feature.name" />
            </div>
          </div>
          <div class="metrics">
            <div
              class="row"
              v-for="(metric, index2) in shownMetrics"
              :key="index2"
            >
              <div class="col-12">
                <label v-show="metric.label">{{ metric.label }}:</label>
                <VehicleMetric
                  :vehicle="vehicle"
                  :metric="metric"
                ></VehicleMetric>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-lg-none mobile-compare-row">
          <div class="row text-left" v-if="feature.name !== 'CompareSummary'">
            <div class="col-5 col-md-3 px-1 text-right">
              <label>Match score</label>
            </div>
            <div
              class="col-2 col-md-3 px-1"
              v-for="(vehicle, idx) in vehicles"
              :key="idx"
            >
              <span :class="['score', getRankClass(vehicle)]">{{
                getVehicleScore(vehicle)
              }}</span>
            </div>
          </div>
          <div class="row text-left" v-if="feature.name !== 'CompareSummary'">
            <div class="col-5 col-md-3 px-1 text-right">
              <label>Match comparison</label>
            </div>
            <div
              class="score col-2 col-md-3 px-1"
              v-for="(vehicle, idx) in vehicles"
              :key="idx"
            >
              <feature-score
                :vehicle="vehicle"
                :feature-name="feature.name"
                :hide-label="true"
              />
            </div>
          </div>
          <div
            class="row px-0"
            v-for="(metric, idx) in shownMetrics"
            :key="idx"
          >
            <div class="col-5 col-md-3 px-1 text-right">
              <label v-show="metric.label">{{ metric.label }}:</label>
            </div>
            <div
              class="col-2 col-md-3 px-1 metric"
              v-for="(vehicle, idx2) in vehicles"
              :key="idx2"
            >
              <VehicleMetric
                :vehicle="vehicle"
                :metric="metric"
              ></VehicleMetric>
            </div>
          </div>

          <div class="row mt-3">
            <div class="offset-5 offset-md-3"></div>
            <div
              class="col-2 col-md-3 px-1"
              v-for="(vehicle, idx2) in vehicles"
              :key="idx2"
            >
              <a
                href="#"
                class="learn-more-mobile"
                target="_blank"
                @click.prevent="navigate(vehicle)"
                >learn more &raquo;</a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="row your-score d-none d-lg-flex"
        v-if="feature.name !== 'CompareSummary'"
      >
        <div class="col" v-for="(vehicle, index) in vehicles" :key="index">
          <a
            href="#"
            target="_blank"
            class="button btn"
            :class="{ tertiary: vehicle.rank !== 1 }"
            @click.prevent="navigate(vehicle)"
            >learn more <span class="accent">&raquo;</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const VehicleMetric = () => import("Components/VehicleMetric");
const FeatureScore = () => import("Components/Compare/FeatureScore");
export default {
  props: {
    feature: Object,
    vehicles: Array,
  },
  methods: {
    showModal() {
      this.$parent.tooltipText = this.feature.tooltip;
      this.$bvModal.show("tooltip-modal");
    },
    getRank(vehicle) {
      return this.sortedVehicleScores.indexOf(vehicle[this.fieldName]) + 1;
    },
    getRankClass(vehicle) {
      if (this.feature.name === "CompareSummary") return "";
      let rank = this.getRank(vehicle);
      return `rank-${rank}`;
    },
    getVehicleScore(vehicle) {
      return Number((vehicle[`${this.feature.name}_Score`] * 100).toFixed(2));
    },
    getRankText(vehicle) {
      let rank = this.getRank(vehicle);
      switch (rank) {
        case 1:
          return { desktop: "Best in Category", mobile: "Best" };
        case 2:
          return { desktop: "2nd in Category", mobile: "2nd" };
        case 3:
          return { desktop: "3rd in Category", mobile: "3rd" };
        default:
          return "";
      }
    },
    navigate(vehicle) {
      this.$emit("navigate", vehicle);
    },
  },
  computed: {
    fieldName() {
      return `${this.feature.name}_Score`;
    },
    sortedVehicleScores() {
      return [...this.vehicles]
        .sort((a, b) => b[this.fieldName] - a[this.fieldName])
        .map((v) => v[this.fieldName]);
    },
    shownMetrics() {
      return this.feature.metrics.filter((m) => !m.hideCompare);
    },
  },
  components: { VehicleMetric, FeatureScore },
};
</script>

<style lang="scss" scoped>
h4 {
  color: $header-font-color;
  font-size: 1.625rem;
  font-weight: 800;
  padding-top: 38px;
  padding-bottom: 10px;
}

label {
  color: $secondary-font-color-dark;
  padding-right: 5px;
  font-weight: bold;
  font-size: 0.94rem;
}

.mobile-compare-row {
  .score {
    font-weight: 700;
    position: relative;
    top: 6px;
  }
  .rank-1 {
    color: $best-color !important;
  }

  .rank-2 {
    color: $second-best-color !important;
  }

  .rank-3 {
    color: $third-best-color !important;
  }
}

.metric {
  padding-top: 3px;
}

span,
label {
  @include media-breakpoint-down(sm) {
    font-size: 0.8rem;
  }
}

i.fa-info-circle {
  color: #38346a;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.metrics {
  font-weight: 600 !important;
}

.compare-summary {
  background-image: url("~Images/blurred_pattern_bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  color: $primary-font-color-light;
  border-top: 2px solid #2b82e3 !important;
  border-bottom: 2px solid #2b82e3;

  .metrics,
  .mobile-compare-row {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  label {
    color: white;
  }
}

.your-score {
  padding-bottom: 20px;
  font-size: 0.75rem;
}

.mobile-match {
  div,
  label {
    font-weight: bold !important;
  }
  label {
    color: black !important;
  }

  div {
    font-size: 0.7rem;
  }
}

.mobile-compare-row {
  padding-bottom: 20px;
  font-size: 0.7rem;
  padding-right: 5px;
  padding-left: 5px;

  .metric {
    position: relative;
    top: 3px;
  }
}

.learn-more-mobile {
  text-decoration: underline;
  font-size: 0.7rem;
}

::v-deep .yes-no-optional {
  position: relative;
  top: -2px;
}
</style>
